
import { Component, Vue } from 'vue-property-decorator';
import Title from '@/components/common/Title.vue';
import OnboardLayout from '@/components/OnboardLayout.vue';

import { AdminService, UserService } from '@/services';
import { UserModule } from '@/store/modules';
import { mdiEye, mdiEyeOff } from '@mdi/js';

@Component({
  components: {
    Title,
    OnboardLayout
  }
})
export default class Login extends Vue {
  rememberMe = false;
  visible = false;

  form = {
    email: '',
    password: ''
  };

  /**
   * Login user
   */
  async onLogin(): Promise<void> {
    const { email, password } = this.form;

    try {
      await AdminService.loginAdmin({ email, password });
      let user = await UserService.fetchUser();
      UserModule.setUser(user);
    } catch (e: any) {
      this.$notifyError(e?.message);

      return;
    }

    this.$router.replace('/admin/offer');
  }

  get appendIcon(): string | null {
    return this.visible ? mdiEye : mdiEyeOff;
  }
  get type(): string {
    return this.visible ? 'text' : 'password';
  }

  onToggleVisibility(): void {
    this.visible = !this.visible;
  }
}
